export const BASE_URL = "https://clinicalapi.scienstechnologies.com";
export const AUTH_URL = `${BASE_URL}/auth/local`;
export const SPONSER_URL = `${BASE_URL}/sponsorers`;
export const STUDIES_URL = `${BASE_URL}/clinical-studies`;
export const STAFF_URL = `${BASE_URL}/staff-profiles`;
export const SITE_STAFF_PIVOT_URL = `${BASE_URL}/site-staff-pivots`;
export const SITE_VISITS_URL = `${BASE_URL}/site-visit-details`;
export const SITES_LIST_URL = `${BASE_URL}/study-sites/findbystudy`;
export const SITES_URL = `${BASE_URL}/study-sites`;
export const ROLES_URL = `${BASE_URL}/users-permissions/roles`;
export const SITE_SUBJECTS_URL = `${BASE_URL}/site-subjects`;
export const PROCESS_DOCS_URL = `${BASE_URL}/process-docs`;
export const FILEUPLOAD_URL = `${BASE_URL}/upload`;
export const MONITOR_SUBJECTS_URL = `${BASE_URL}/monitor-documents/findbysubject`;
export const MONITOR_DOC_URL = `${BASE_URL}/monitor-documents`;
export const MONITOR_QUERY_COMMENTS_URL = `${BASE_URL}/monitor-query-comments`;
export const MONITOR_DOC_QUERIES_URL = `${BASE_URL}/monitor-document-queries`;
export const MONITOR_DOC_VERSIONS_URL = `${BASE_URL}/monitor-document-versions`;
export const MONITOR_REPORTS_URL = `${BASE_URL}/monitor-reports`;
export const AUDIT_TRAIL_URL = `${BASE_URL}/activity-loggers/downloadxl`;
export const NOTIFICATION_STATUSES = `${BASE_URL}/notifications-statuses`;
export const NOTIFICATION_STATUSES_UPDATE = `${BASE_URL}/notifications-statuses/markallread`;
export const FORGET_PASSWORD = `${BASE_URL}/auth/forgot-password`;
export const RESET_PASSWORD = `${BASE_URL}/auth/reset-password`;
export const ACTIVATE_ACCOUNT = `${BASE_URL}/auth/email-confirmation?confirmation=`;
export const FILE_DOWNLOAD_URL = `${BASE_URL}/storage/azure/download`

export const PAGE_SIZE = 10;
