import React from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Navbar, RootSidebar } from "components"
import { withRouter } from "react-router-dom";
// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";
// react plugin for creating notifications
import NotificationAlert from "react-notification-alert";
import { StudyActions, SiteActions } from 'actions'
import { Routes, Roles } from "constants/index.js";
import { ChangeStudySite } from "../views/Protocol/Components";
import { updateStaff } from "api/index";

class DashboardLayout extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            backgroundColor: "quanticate-blue",
            accepted: false,
            sites: [],
            preSelectStudy: undefined,
            preSelectSite: undefined,
            selectedStudy : undefined,
            selectedSite : undefined,
        }
    }

    notificationAlert = React.createRef();

    componentDidMount() {
        if (this.props.user && this.props.user.staffProfile) {
            this.props.getStudiesByStaff(this.props.token, this.props.user.staffProfile._id, this.props.user.role.name)
        }
    }

    static getDerivedStateFromProps(props, state) {
        var newstate = state;

        if (props.activeSites) {
            newstate['sites'] = props.activeSites;
        }

        return newstate;
    }

    hideAlert = () => {
        this.setState({
            alert: undefined,
        });
    }

    onSearchFocus = (e) => {
        this.props.history.push(`${Routes.SEARCH}`)
    }

    onStudySelect = async (study) => {
        await this.setState({ preSelectStudy: study});
		switch (this.props.role) {
			case Roles.SITE_SCANNER_RESOLVER:
            case Roles.SITE_SCANNER:
			case Roles.CCRA:
				const sites = this.props.studies && study && this.props.studies.filter(val => val.clinicalStudy._id === study.clinicalStudy._id);
				this.setState({sites: sites});
                break;
            case Roles.DM:
            case Roles.PM:
            case Roles.DE:
            case Roles.CSM:
                this.props.getActiveSites(this.props.token, study.clinicalStudy._id);
                break;
            default:
                break;
        }
		await this.saveAndProceed();
    }

	saveAndProceed = async () => {
		
		let navigate = false;
		
		// check if guidelines are accepted
		if (this.state.accepted === false) {
			return;
		}
		
		console.log(this.state);
		
		switch (this.props.role) {
			case Roles.SITE_SCANNER_RESOLVER:
            case Roles.SITE_SCANNER:
				if (this.state.preSelectStudy) {
					// update current study details here
					this.props.getStudyDetails(this.props.token, this.state.preSelectStudy.clinicalStudy._id);
					this.props.getSiteDetails(this.props.token, this.state.preSelectStudy.studySite._id);
					this.setState({ 
						selectedStudy: this.state.preSelectStudy.clinicalStudy, 
						selectedSite: this.state.preSelectStudy.studySite 
					});
					
					// update default study site for the user
					await updateStaff(this.props.token,
						this.props.user.staffProfile.id,
						{ defaultStudySite: this.state.preSelectStudy.studySite._id }
					);
					navigate = true;
				}
				break;	
			case Roles.CCRA:
            case Roles.DM:
            case Roles.PM:
            case Roles.DE:
            case Roles.CSM:
				if (this.state.preSelectStudy && this.state.preSelectSite) {
					// update current site and study details here
					this.props.getStudyDetails(this.props.token, this.state.preSelectStudy.clinicalStudy._id);
					this.props.getSiteDetails(this.props.token, this.state.preSelectSite._id);
					
					this.setState({ 
						selectedStudy: this.state.preSelectStudy.clinicalStudy, 
						selectedSite: this.state.preSelectSite.studySite 
					});
                    
					// update default study site for the user
                    await updateStaff(this.props.token,
                        this.props.user.staffProfile.id,
                        { defaultStudySite: this.state.preSelectSite.studySite._id });
					navigate = true;
				}
				break;
            default:
                break;
        }
		
		if (navigate) {
			this.props.history.go(`${Routes.DASHBOARD}`);
			this.hideAlert();
        }
	}

    onSiteSelect = async (site) => {
        await this.setState({ preSelectSite: site })
		await this.saveAndProceed();
    }

    onAcceptGuidelines = async (accepted) => {
		await this.setState({ accepted: accepted ? true : false });
		this.saveAndProceed();
    }

    onStudyChange = async () => {
        await this.setState({ alert : true, selectedStudy : undefined, selectedSite : undefined, accepted : false});
    }

    render() {
        const { children, role, user } = this.props;
        return (
            <div className="wrapper">
                <NotificationAlert ref={this.notificationAlert} />
                <RootSidebar
                    {...this.props}
                    role={role}
                    user={user}
                    backgroundColor={this.state.backgroundColor}
                />
                <div className="main-panel" ref={this.mainPanel}>
                    <Navbar
                        onSearchFocus={this.onSearchFocus}
                        onStudyChange={this.onStudyChange}
                        onSearchChange={this.props.onSearchChange}
                        searchFocus={this.props.searchFocus}
                    />
                    <>
                        {
                            this.state.alert &&
                            <SweetAlert
                                title={''}
                                showConfirm={false}
                                onConfirm={function () {
                                    console.log('on confirm');
                                }}
                                // confirmBtnStyle={{ display: 'none' }}
                                // showCloseButton
                                onCancel={this.hideAlert}>
                                <div style={{ maxHeight: '500px', margin: '20px' }}>
                                    <ChangeStudySite
                                        role = {this.props.role}
                                        onStudySelect={this.onStudySelect}
                                        onSiteSelect={this.onSiteSelect}
                                        onAcceptGuidelines={this.onAcceptGuidelines}
                                        studies={this.props.studies}
                                        sites={this.state.sites} 
                                        site={this.state.selectedSite}
                                        study={this.state.selectedStudy}/>
                                </div>
                            </SweetAlert>
                        }
                        {children}
                    </>
                </div>
            </div>
        );
    }
}

DashboardLayout.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    title: PropTypes.string,
    role: PropTypes.string
};

const mapStateToProps = ({ user, studies, sites }) => {
    return {
        token: user.token,
        role: user.role ? user.role.name : '',
        user: user,
        studies: studies.myStudies,
        study: studies.current,
        site: sites.current,
        activeSites: studies.activeSites,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getStudiesByStaff: (token, staffId, role) => StudyActions.getStudiesByStaff(dispatch, token, staffId, role),
        getStudyDetails: (token, studyId) => StudyActions.getStudyDetails(dispatch, token, studyId),
        getSiteDetails: (token, siteId) => SiteActions.getSiteDetails(dispatch, token, siteId),
        getActiveSites: (token, studyId) => StudyActions.getActiveSites(dispatch, token, studyId)
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardLayout));